.header {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    align-items: center;

}
.heading {

}

.sub-heading{
    color: #E41A67;
}

.disclaimer {
    font-size: 0.6rem;
    width: 50%;
}

.outputs-heading{
    margin: 0px 0px 10px;
}

.calculator{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-content:center ;
    max-width:80%;
    align-items: center
}

.results-button{
width: 40vw;
margin: 1.5rem 0 2rem 0;
padding: 1rem;
    border-radius: 2rem;
    background-color: #E41A67;
    color: white;
    border: transparent;
    font-weight: bold;
    font-size: 1rem;
}

.calc-input{
    margin-bottom: 1.5rem;
    background-color: #f3f2f2;
}
@media screen and (max-width: 1050px){
    .timeline-container{
       display:none;
    }
}

@media screen and (max-width: 1000px) {
    /* Change grid layout to a single column */
    .calculator {
        grid-auto-flow:row;
        grid-template-columns: 1fr; /* One column */
    }

    .results-button{
        display:none;
    }
    .results-button-mobile{
    display:block;
        width: 40vw;
        margin: 1.75rem 0 2rem 0;
        padding: 1rem;
        border-radius: 2rem;
        background-color: #E41A67;
        color: white;
        border: transparent;
        font-weight: bold;
        font-size: 1rem;
    }
}
.submit-button:disabled{
    color:#afafaf;
    background-color: gainsboro;
}

@media screen and (min-width: 1001px){
    .results-button-mobile{
        display: none;
    }
}


.calculator > div{
margin: 10px;
min-width: 300px;
align-content: center;

}


.outputs-column{


    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    height: 100%;
}

.outputs{
    background-color: #f3f2f2;
    border-radius: 10px;
    width: 80%;
    margin-bottom: 20px;
    padding:20px 20px 20px 20px;
    min-height: 19rem;
    min-width: 20rem;
}
.output-container{
    border-bottom: solid;
    border-color: #afafaf;
    border-width: 2px;

    width: 100%;


}

.output-container:last-of-type{

}

input:not([type=checkbox], [type=submit])
{
    width: 15rem;
    min-height: 2rem;
   background-color: #f3f2f2;
    border-radius: 20px;
    padding-left: 0.5rem;

    border-color: transparent;
}

select{
    width: 15rem;
    min-height: 2.4rem;
    background-color: #f3f2f2;
    border-radius: 20px;
    padding-left: 0.5rem;
    border-color: transparent;
}

textarea:focus {
    outline: none !important;
    border-color: #E41A67;
    box-shadow: 0 0 10px #E41A67;
}

.checkbox-container{
    display: flex;
    flex-direction: row;
    padding: 0 1rem 1rem 0;
    color: gray;
}

.checkbox-round {
    width: 1.5rem;
    height: 1.5rem;
    margin: 1rem 1rem 1rem 0;
    background-color: #eeeeee;
    border-radius: 50%;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;

}

.checkbox-round:checked {
    background-color: #E41A67;
    font-weight: bold;
}

.checkboxes {
    display: flex;
    flex-direction: column;


}

.output{

    font-size: 2rem;
    padding: 0.5rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
}
.calc-column{
    align-content: start;
    align-items: start;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    height: 100%;
    text-align: left;
    font-size: 0.85rem;
    font-weight: bold;
}


.timeline-element-container{
    width : 6rem;
    height: 6rem;
    border-radius: 50%;
    align-items: center;
    align-content: center;

}

.timeline-inner-element{
    width : 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background-color: #696969;
    transform: translateX(0.75rem);

}

.timeline-icon{
  padding:0.7rem;

}

.svg-icon{
    width: 2rem;
    height: 2rem;
    fill: white;
}

.timeline{
    display: flex;
    flex-direction: row;
    padding-left: 0.2rem;
    flex:1;
    height: 10rem;
    width: calc(55vw);
}

.indicator-border{
    border: solid;
    border-width: 0.2rem;
    border-color: #282c34;
    height: 10rem;
    width: calc(55vw);
    position:absolute;
    border-radius: 10Rem;
    z-index: -3;
}

.dotted-line{
    content: '';
    border: none;
    border-top: 4px dotted #646464;
    color: #fff;
    height: 30px;
    width: 49vw;

    transform: translate(6%, 4.5rem);
    z-index: 0;
    position: absolute;

}

.timeline-indicator{
    /*width: calc(55vw * 0.1428*7);*/
    /*margin-left: calc(55vw * 0.1428*0);*/
    content: '';
    border-width: 0.5rem;
    height:10rem;
    z-index: -1;
    position: absolute;
    border-radius: 10rem;
    border: solid;

    border-width: 0.2rem;
    /*//border-color: orangered;*/
    transition:  0.5s;
    transition-timing-function: ease-in-out;
    //width:10%;
    flex:1;

}

.timeline-element-section{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    z-index: 3;
    width: 14.26%;
    padding-left: 3%;
    padding-right: 1%;
    padding-top: 1rem;

}

.timeline-covered{

    width: 20rem;
}

.timeline-title{
    font-size: 0.6rem;
    padding-top: 1rem;
    transform: translateX(0.75rem);
    position: relative;
    height: 1rem;
    width: 4rem;
}

.selection-indicator{
    background-color: #E41A67;
    height: 100%;
    width: 100%;
}

.exposure-icon{
    color: white;
    padding: 0px 10px 0 10px;
    border-radius: 8px;
    font-size: 1.2rem;
    align-items: center;
    align-content: center;
}


.text-banner{
    padding-top: 3rem;
    padding-bottom: 2rem;
    font-weight: bold;
    color:#E41A67;
    font-size: 1.1rem;
}


.protection-banner{
    font-weight: bold;
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.results-section{

    transition-duration: 0.2s;
    transition: ease-in;
}


.output-placeholder{
    align-content: center;

    text-align: center;
    color: #5d5d5d;
    display: flex;
    flex-direction: column;
}
